import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';
import Confetti from 'react-confetti';
import '../styles/HeroCom.css';

function HeroCom() {
  const [showConfetti, setShowConfetti] = useState(false);

  const handleJoinCommunity = () => {
    console.log('Button clicked, showConfetti:', showConfetti); // Debug log
    setShowConfetti(true);
    // Delay the redirect to allow confetti to play
    setTimeout(() => {
      window.open('https://airtable.com/app0vKGbolh7nGxVx/shrIaQ0EFBKN5sVmc', '_blank');
      setShowConfetti(false); // Reset confetti after redirect
    }, 2000); // 2 seconds delay to let confetti play
  };

  const { number } = useSpring({ from: { number: 0 }, to: { number: 30 }, delay: 200, config: { duration: 2000 } });
  const [index, setIndex] = useState(0);

  return (
    <section className="hero">
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          colors={['#E9B44C', '#50A2A7', '#023844']}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
      <div className="hero-container">
        <div className="content-wrapper">
          <div className="content-container">
            <div className="text-content">
              <motion.h1
                className="hero-heading"
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Where F&B leaders come together
              </motion.h1>
              <motion.p
                className="hero-subheading"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                A community for Food & Beverage managers to learn, network and grow together
              </motion.p>
            </div>
            <div className="stats-section">
              <img src="/community/fb-managers.png" className="stats-image" alt="Community statistics" loading="lazy" />
              <div className="stats-number">
                <animated.span className="big-number">
                  {number.to(n => `${Math.floor(n)}+`)}
                </animated.span>
                <span className="stats-label">F&B managers</span>
              </div>
            </div>
            <div className="cta-section">
              <motion.button
                className="cta-button" // Add 'glowing' class for animation
                onClick={handleJoinCommunity}
                whileHover={{ scale: 1.05, backgroundColor: '#50a2a7' }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
                aria-label="Join the Food & Beverage managers community on Slack"
              >
                Join the Community
              </motion.button>
            </div>
          </div>
        </div>
        <div className="hero-image-wrapper">
          <motion.img
            src="/community/community-landing.png"
            className="hero-image"
            alt="F&B community illustration"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export default HeroCom;