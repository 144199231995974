import { useEffect, useState } from 'react';
import '../styles/Header.css';

/* eslint-disable */

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [location, setLocation] = useState('');
  const [smallscreen, setSmallscreen] = useState(false);
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const path = window.location.pathname.replace(/\/$/, ''); // Normalize by removing trailing slash
    setLocation(path);
  }, []);
  return (
    <main className='!bg-[#F5FAFA]'>
    <header className='!bg-[#F5FAFA] px-4 md:!px-8'>
      <article className='header_article'>
        <a href='https://www.shukran.co' className='header_article_figure'>
          <img
            src='/images/resources/shukranbluelogo.png'
            className='header_article_figure_img'
            alt='blue shukran logo'
          />
        </a>
        <nav className={`header_article_nav ${isMenuOpen ? 'open' : ''}`}>
            <a
              href='https://www.shukran.co/hotels'
              onClick={() => setIsMenuOpen(false)}
              className='header_article_a'
            >
              Hotels
            </a>
            <a
              href='https://www.shukransacco.com/?utm_source=tippingLP&utm_medium=web'
              onClick={() => setIsMenuOpen(false)}
              className='header_article_a'
              rel='noreferrer'
              target='_blank'
            >
              Service Workers
            </a>
            <a
              href='/'
              onClick={() => setIsMenuOpen(false)}
              className='header_article_a'
            >
              Community
            </a>
            <a
              href='https://www.shukran.co/impact'
              onClick={() => setIsMenuOpen(false)}
              className='header_article_a'
            >
              Impact
            </a>
          {/* <a
            href='/matchtip'
            onClick={() => setIsMenuOpen(false)}
            className='header_article_a'
          >
            Tip Matching
          </a> */}

            {/* <a
              href='/community'
              onClick={() => setIsMenuOpen(false)}
              className='header_article_a'
            >
              Foodie Community
            </a> */}


          <div className='dropdown'>
              <button type='button' className='header_article_nav_link dropbtn'>
                Resources
                <span className='dropbtn_span'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='23'
                    height='23'
                    viewBox='0 0 23 23'
                    fill='none'
                  >
                    <path
                      d='M19.0902 8.57703L12.8418 14.8254C12.1039 15.5633 10.8964 15.5633 10.1585 14.8254L3.91016 8.57703'
                      stroke='#303030'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </button>
            <div className='dropdown-content'>
              {/* <a
                href='/testimonial'
                onClick={() => setIsMenuOpen(false)}
                className='header_article_nav_link_a'
              >
                Testimonials
              </a> */}
              <a
                href='https://www.shukran.co/faqs'
                className='header_article_nav_link_a'
                onClick={() => setIsMenuOpen(false)}
              >
                FAQs
              </a>
              <a
                href='https://blog.shukran.co/'
                className='header_article_nav_link_a'
                onClick={() => setIsMenuOpen(false)}
              >
                Blog
              </a>
            </div>
          </div>
            <a
              href="https://airtable.com/app0vKGbolh7nGxVx/shrIaQ0EFBKN5sVmc"
              style={{ textDecoration: 'none' }}
              className='hover:text-[#fff] p-4 hover:!no-underline'
            >
              <span
                className='header_article_nav_link buttones join-button py-2 px-10 !bg-[#E9B44C] !text-[#fff] hover:!bg-[#50a2a7] hover:!no-underline hover:!text-[#fff] transition-all'
              >
                Join
              </span>
            </a>
        </nav>
        <button
          type='button'
          className='icon'
          id='button'
          onClick={handleMenuToggle}
        >
          <i className='fa fa-bars' />
        </button>
      </article>
    </header>
    </main>
  );
}


export default Header;