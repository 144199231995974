import { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion
import '../styles/JoinCom.css';

function JoinCom() {
  // Animation variants for the cards
  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  return (
    <section className="about-us-section">
      <header className="about-us-header">
        <h2 className="benefits-title">Benefits...</h2>
        <p className="benefits-subtitle">A space for collaboration and growth</p>
      </header>

      <div className="benefits-container">
        <motion.article
          className="benefit-card"
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }} // Trigger when 30% of the card is in view
        >
          <div className="benefit-content">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/f1e9796beba845a68d3736ea027d90ee/7cb922483a69cbe9976582c6649a21f197bdfee91542dd13fa0df910f2ac13de?placeholderIfAbsent=true"
              className="benefit-icon"
              alt="Illustration of two people collaborating for career growth and mentorship opportunities"
            />
            <h3 className="benefit-title">Opportunity</h3>
            <p className="benefit-description">
              Opportunities for career growth, jobs, and mentorship
            </p>
          </div>
        </motion.article>

        <motion.article
          className="benefit-card"
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <div className="benefit-content">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/f1e9796beba845a68d3736ea027d90ee/e7da4d9da9a5abda81a8c56c9477b15a67f877939774d02813feaa3508534c9c?placeholderIfAbsent=true"
              className="benefit-icon"
              alt="Illustration of a person accessing learning resources on a mobile device for F&B professionals"
            />
            <h3 className="benefit-title">Learning</h3>
            <p className="benefit-description">
              Access valuable resources<br />
              tailored for F&B professionals
            </p>
          </div>
        </motion.article>

        <motion.article
          className="benefit-card"
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <div className="benefit-content">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/f1e9796beba845a68d3736ea027d90ee/a6adf8233ff8af36520145dd74cdcb893b91e7b90840f8ff375427c82e499cfe?placeholderIfAbsent=true"
              className="benefit-icon"
              alt="Illustration of a world map showing connections for networking with industry peers"
            />
            <h3 className="benefit-title">Networking</h3>
            <p className="benefit-description">
              Meet and connect with<br />
              industry peers
            </p>
          </div>
        </motion.article>
      </div>
    </section>
  );
}

export default JoinCom;