import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HeroCom from './components/HeroCom';
import JoinCom from './components/JoinCom';
import EventCom from './components/EventCom';
import StoryCom from './components/StoryCom';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
const AnimatedSection = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

// eslint-disable-next-line arrow-body-style
const App = () => {
  return (
    <div>
      <Header />
      
      <AnimatedSection className="bg-[#F5FAFA]">
        <HeroCom />
      </AnimatedSection>

      <AnimatedSection className="bg-[#fff]">
        <JoinCom />
      </AnimatedSection>
      <AnimatedSection className="bg-[#fff]">
        <EventCom />
      </AnimatedSection>

      <AnimatedSection className="bg-[#fff]">
        <StoryCom />
      </AnimatedSection>
      <WhatsAppButton />
      <Footer />
    </div>
  );
};

export default App;
