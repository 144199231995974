import { useState } from 'react';
import { motion } from 'framer-motion';
import '../styles/StoryCom.css';

function StoryCom() {
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    if (email) {
      console.log('Subscribing with email:', email);
      // Here you would typically call an API to handle the subscription
      window.location.href = 'https://blog.shukran.co/';
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <section className="newsletter-section">
      <div className="newsletter-content">
        <motion.h2
          className="newsletter-heading"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          Join our newsletter
        </motion.h2>
        <motion.p
          className="newsletter-description"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          viewport={{ once: true }}
        >
          Receive updates and news directly in your inbox
        </motion.p>
        
        <motion.div 
          className="subscription-form"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          viewport={{ once: true }}
        >
          <input
            type="email"
            className="email-input"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
            aria-label="Email Address"
          />
          <motion.button
            className="subscribe-button"
            onClick={handleSubscribe}
            whileHover={{ scale: 1.05, backgroundColor: '#50a2a7' }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
            aria-label="Subscribe to our newsletter"
          >
            Subscribe
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
}

export default StoryCom;