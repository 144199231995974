import React, { useEffect, useRef } from 'react';
import '../styles/EventCom.css'; // Assuming styles are moved to a separate CSS file


const engageUsImage = '/community/engage-us.png';
// TypeScript interfaces
const PlatformItem = {
  id: String,
  title: String,
  description: String || undefined,
  imageUrl: String,
  logoUrl: String,
  logoPosition: 'top-right' || 'top-left',
  altText: String,
  link: String || undefined,
};

const Props = {
  title: String || undefined,
  subtitle: String || undefined,
  sectionId: String || undefined,
  platforms: Array || undefined,
  platforms1: Array || undefined,
  backgroundColor: String || undefined,
  centerPosition: Number || undefined,
};

// Default platforms data
const defaultPlatforms = [
  {
    id: 'slack',
    title: 'Slack',
    description: 'Join our active Slack workspace for daily discussions',
    imageUrl: '/community/place1.webp',
    logoUrl: '/community/slack.webp',
    logoPosition: 'top-right',
    altText: 'Screenshot of our Slack community workspace',
    link: '#',
  },
  {
    id: 'meetups',
    title: 'Events',
    description: 'Connect with fellow F&B leaders and industry experts at exclusive meetups.',
    imageUrl: '/community/place2.webp',
    logoUrl: '/community/events.png',
    logoPosition: 'top-right',
    altText: 'Community members at an in-person meetup event',
    link: '#',
  },
  {
    id: 'webinars',
    title: 'Webinars',
    description: 'Participate in online webinars with industry experts',
    imageUrl: '/community/place3.webp',
    logoUrl: '/community/webnar.webp',
    logoPosition: 'top-right',
    altText: 'Online webinar with multiple participants',
    link: '#',
  },
];

const defaultPlatforms1 = [
  {
    id: 'meetups',
    title: 'Events',
    description: 'Connect with fellow F&B leaders and industry experts at exclusivity meetups.',
    imageUrl: '/community/place2.webp',
    logoUrl: '/community/events.png',
    logoPosition: 'top-right',
    altText: 'Community members at an in-person meetup event',
    link: '#',
  },
  {
    id: 'webinars',
    title: 'Webinars',
    description: 'Participate in online webinars with industry experts',
    imageUrl: '/community/place3.webp',
    logoUrl: '/community/webnar.webp',
    logoPosition: 'top-right',
    altText: 'Online webinar with multiple participants',
    link: '#',
  },
  {
    id: 'slack',
    title: 'Slack',
    description: 'Join our active Slack workspace for daily discussions',
    imageUrl: '/community/place1.webp',
    logoUrl: '/community/slack.webp',
    logoPosition: 'top-right',
    altText: 'Screenshot of our Slack community workspace',
    link: '#',
  },
];

const WhereItHappens = ({
  title = 'Where It Happens',
  subtitle = 'Connect with our community across multiple platforms',
  sectionId = 'where-it-happens',
  platforms = defaultPlatforms,
  platforms1 = defaultPlatforms1,
  backgroundColor = '#FFF',
  centerPosition = 0,
}) => {
  const platformRefs = useRef([]);

  // Determine platforms based on centerPosition
  const leftPlatform = platforms[centerPosition === 0 ? 1 : 0];
  const centerPlatform = platforms[centerPosition];
  const rightPlatform = platforms[centerPosition === 2 ? 1 : 2];

  const leftPlatformMobile = platforms1[centerPosition === 0 ? 1 : 0];
  const centerPlatformMobile = platforms1[centerPosition];
  const rightPlatformMobile = platforms1[centerPosition === 2 ? 1 : 2];

  // Animation and event handling
  useEffect(() => {
    initAnimations();
    trackPlatformClicks();

    const handleResize = () => {
      const isDesktop = window.innerWidth >= 1115;
      const desktopPlatforms = document.querySelector('.desktop-platforms');
      const mobilePlatforms = document.querySelector('.mobile-platforms');
      if (desktopPlatforms && mobilePlatforms) {
        desktopPlatforms.style.display = isDesktop ? 'grid' : 'none';
        mobilePlatforms.style.display = isDesktop ? 'none' : 'grid';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initAnimations = () => {
    const isDesktop = window.innerWidth >= 1115;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateEntry(entry.target, isDesktop);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2, rootMargin: '0px 0px -50px 0px' }
    );

    platformRefs.current.forEach((item, index) => {
      if (item) {
        prepareForAnimation(item, index, isDesktop);
        observer.observe(item);
      }
    });
  };

  const prepareForAnimation = (element, index, isDesktop) => {
    element.style.opacity = '0';
    if (isDesktop) {
      if (element.classList.contains('center-platform')) {
        element.style.transform = 'translateY(0)';
      } else if (element.classList.contains('left-platform')) {
        element.style.transform = 'translateY(20px) translateX(20px)';
      } else {
        element.style.transform = 'translateY(20px) translateX(-20px)';
      }
    }

    const delay = element.classList.contains('center-platform')
      ? 0.1
      : element.classList.contains('left-platform')
      ? 0.3
      : 0.5;

    element.style.transition = isDesktop
      ? `opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ${delay}s, transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ${delay}s, box-shadow 0.4s ease, border-color 0.3s ease`
      : `opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ${delay}s`;
  };

  const animateEntry = (element, isDesktop) => {
    element.style.opacity = '1';
    if (isDesktop) {
      if (element.classList.contains('center-platform')) {
        element.style.transform = 'translateY(0)';
      } else if (element.classList.contains('left-platform')) {
        element.style.transform = 'translateY(0) translateX(0)';
      } else if (element.classList.contains('right-platform')) {
        element.style.transform = 'translateY(0) translateX(0)';
      }
    }
  };

  const trackPlatformClicks = () => {
    const platformLinks = document.querySelectorAll('.platform-link');
    platformLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const platformItem = link.closest('.platform-item');
        const platformId = platformItem.dataset.platformId;
        const platformTitle = platformItem.querySelector('.platform-title').textContent;

        if (typeof window.gtag === 'function') {
          window.gtag('event', 'platform_click', {
            event_category: 'Engagement',
            event_label: platformId,
            platform_name: platformTitle,
          });
        }
      });
    });
  };

  const renderPlatformItem = (platform, className, index) => (
    <div
      className={`platform-item ${className}`}
      data-platform-id={platform.id}
      ref={(el) => (platformRefs.current[index] = el)}
    >
      <a href={platform.link || '#'} className="platform-link pointer-events-none">
        <div className="platform-image-container">
          <img
            src={platform.imageUrl}
            alt={platform.altText}
            className="platform-image"
            width="470"
            height="254"
            loading="lazy"
          />
          <img
            src={platform.logoUrl}
            alt={`${platform.title} logo`}
            className={`platform-logo ${platform.logoPosition}`}
            width="80"
            height="80"
          />
          <div className="platform-overlay">
            <h3 className="platform-title">{platform.title}</h3>
            {platform.description && (
              <p className="platform-description">{platform.description}</p>
            )}
          </div>
        </div>
      </a>
    </div>
  );

  return (
    <section 
      id={sectionId} 
      className="where-it-happens-section" 
      style={{ 
        backgroundColor, 
        backgroundImage: `url(${engageUsImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container">
        <div className="section-header">
          <h2 className="section-title">{title}</h2>
          {/* Uncomment if subtitle is needed */}
          {/* <p className="section-subtitle">{subtitle}</p> */}
        </div>

        {/* Desktop Platforms */}
        <div className="platforms-container desktop-platforms">
          {renderPlatformItem(centerPlatform, 'center-platform', 0)}
          {renderPlatformItem(leftPlatform, 'left-platform second-item', 1)}
          {renderPlatformItem(rightPlatform, 'right-platform', 2)}
        </div>

        {/* Mobile Platforms */}
        <div className="platforms-container mobile-platforms">
          {renderPlatformItem(centerPlatformMobile, 'center-platform', 3)}
          {renderPlatformItem(leftPlatformMobile, 'left-platform second-item', 4)}
          {renderPlatformItem(rightPlatformMobile, 'right-platform', 5)}
        </div>
      </div>
    </section>
  );
};

export default WhereItHappens;